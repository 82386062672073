<template>
    <div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column label="编号" prop="id"></el-table-column>
            <el-table-column label="状态名" prop="status_name"></el-table-column>
            <el-table-column label="状态描述" prop="status_des"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt doc">
                    <el-button size="mini" type="primary" icon="el-icon-edit"  @click="edit(scope.row)">编辑</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="修改描述" :visible.sync="dialogFormVisible">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="状态名">
                    <el-input v-model="form.status_name" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="状态描述">
                    <el-input v-model="form.status_des"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="sub">保存</el-button>
                    <el-button @click="dialogFormVisible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                dialogFormVisible: false,
                form: {
                    status_des: '',
                    status_name: '',
                    id: ''
                }
            }
        },
        mounted() {
            this.axios.get('/userstatus/get_gu_user_status')
                .then(res => {
                    if (res.data.code == 0) {
                        this.list = res.data.result;
                    } else { console.log(res); }
                })
                .catch(res => {
                    console.log(res)
                })
        },
        methods: {
            edit(row) {
                this.dialogFormVisible = true;
                this.form.status_name = row.status_name;
                this.form.status_des = row.status_des;
                this.form.id = row.id;
            },
            sub() {
                this.axios.post('/userstatus/update_gu_user_status', this.qs.stringify(this.form))
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '修改成功'
                            });
                            this.dialogFormVisible = false;
                            this.reload();
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    })
                    .catch(res => { console.log(res) })
            }
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }
</style>